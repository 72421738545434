import * as React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/utilitary.css'
import '../styles/style.css'
import { Link } from "gatsby"
import mainfoto from "../images/imagem-section12.png" // Tell webpack this JS file uses this image
import cardfoto1 from "../images/steak-card.jpg" // Tell webpack this JS file uses this image

import cardfoto2 from "../images/nacos-card.jpg" // Tell webpack this JS file uses this image
import cardfoto3 from "../images/Bifemanteiga-card.jpg" // Tell webpack this JS file uses this image
import cardfoto4 from "../images/maminha2.png" // Tell webpack this JS file uses this image

import scrollTo from 'gatsby-plugin-smoothscroll';


import dificult from "../images/dificuldade-vector.png" // Tell webpack this JS file uses this image
import dificult2 from "../images/diff-media.png" // Tell webpack this JS file uses this image
import dificult3 from "../images/diff-alta.png" // Tell webpack this JS file uses this image
import diff1 from "../images/diff-1.png" // Tell webpack this JS file uses this image
import diff2 from "../images/diff-2.png" // Tell webpack this JS file uses this image

import time from "../images/time-vector.png" // Tell webpack this JS file uses this image
import time20 from "../images/tempo-20.png" // Tell webpack this JS file uses this image
import time25 from "../images/tempo-25.png" // Tell webpack this JS file uses this image
import time40 from "../images/tempo-40.png" // Tell webpack this JS file uses this image
import time30 from "../images/tempo-30.png" // Tell webpack this JS file uses this image
import time90 from "../images/tempo-90.png" // Tell webpack this JS file uses this image
import time45 from "../images/tempo-45.png" // Tell webpack this JS file uses this image

import carnefly from "../images/meatshadow.png" // Tell webpack this JS file uses this image
import angus from "../images/angus-icon.png" // Tell webpack this JS file uses this image
import angusinit from "../images/angus-init.png" // Tell webpack this JS file uses this image

import logoblack from "../images/logo.png" // Tell webpack this JS file uses this image
import raca from "../images/racaanimal.png" // Tell webpack this JS file uses this image
import maturacao from "../images/maturacao.png" // Tell webpack this JS file uses this image
import sust from "../images/sustentavel.png" // Tell webpack this JS file uses this image
import logo from "../images/logo2.png" // Tell webpack this JS file uses this image
import certis from "../images/certis.png" // Tell webpack this JS file uses this image
import frescura from "../images/selo1.png" // Tell webpack this JS file uses this image
import frescura2 from "../images/selo2.png" // Tell webpack this JS file uses this image
import check from "../images/checkmsc.png" // Tell webpack this JS file uses this image

import fundo1 from "../images/fundo-1.png" // Tell webpack this JS file uses this image
import bac1 from "../images/nacos-card.jpg" // Tell webpack this JS file uses this image




import Helmet from "react-helmet"




const IndexPage = () => (
<Layout>


   <div id="section-1" className="white-bg">
      <div className="wrapper-container">
      <div className="right-col mobile">
            <div className="right-element" data-sal="slide-up" data-sal-delay="400"  data-sal-duration="800" data-sal-easing="ease">
               <img src={fundo1} alt="Logo" />
            </div>
         </div>
         <div className="left-col">
            <div  className="left-element" >
               <div className="slogan ">
                  <p className="aller-display titulo">Dos USA <br></br> para a sua mesa</p>
                  <p className="desc sneak-regular">Animais criados em vastas <br></br>planícies ricas em cereais.</p>
                  <button onClick={() => scrollTo('#section-2')} className="btn-sugestoes absara">Descubra as nossas sugestões</button>
                  <div className="logos">
                     <div className="cert">
                  <img  src={frescura} alt="" />
                  </div>
                  <div className="cert2">
                  <img  src={frescura2} alt="" />
                  </div>
                  <div className="angus">
                  <img  src={certis} alt="" />
                  </div>

                  </div>
               </div>
            </div>
         </div>
         <div className="right-col desktop">
            <div className="right-element" data-sal="slide-up" data-sal-delay="400"  data-sal-duration="800" data-sal-easing="ease">
               <img src={fundo1} alt="Logo" />
            </div>
         </div>
      </div>
   </div>
   <div id="section-2" className="white">
      <div className="wrapper-container">
         <div className="left-col">
         <Link to="/maminha/">
            <div className="card-recipe" >
               <div className="wrapper">
                  <img src={cardfoto4} alt="Logo" />
                  <div className="devider">
                  </div>
                  <div className="titulo absara">
                    Maminha no Forno com molho BBQ caseiro
                  </div>
                  <div className="descr sneak-regular">
                  O molho BBQ agridoce é a combinação perfeita de sabores que contrasta com a gordura da carne.
                  </div>
                  <div className="caracts">
                     <div className="time-vector">
                        <img src={time90} alt="Logo" />
                     </div>
                     <div className="diff-vector">
                        <img src={diff2} alt="Logo" />
                     </div>
                  </div>
               </div>
            </div>
            </Link>
         </div>
         <div className="left-center-col">
         <Link to="/steak-sandwich/">
            <div className="card-recipe" >
               <div className="wrapper">
                  <img src={cardfoto1} alt="Logo" />
                  <div className="devider">
                  </div>
                  <div className="titulo absara">
                    Steak Sandwich com Cebola e Cheddar
                  </div>
                  <div className="descr sneak-regular">
                  Uma receita de fácil confeção. União perfeita do prático e do tradicional.
                  </div>
                  <div className="caracts">
                     <div className="time-vector">
                        <img src={time45} alt="Logo" />
                     </div>
                     <div className="diff-vector">
                        <img src={diff2} alt="Logo" />
                     </div>
                  </div>
               </div>
            </div>
            </Link>
         </div>
         <div className="center-col">
         <Link to="/nacos/">
            <div className="card-recipe" >
               <div className="wrapper">
                  <img src={cardfoto2} alt="Logo" />
                  <div className="devider">
                  </div>
                  <div className="titulo absara">
                  Nacos com Batata Doce Assada na Grelha
                  </div>
                  <div className="descr sneak-regular">
                  Os nacos grelhados na brasa deixam evidenciar o puro sabor da carne.
                  </div>
                  <div className="caracts">
                     <div className="time-vector">
                        <img src={time45} alt="Logo" />
                     </div>
                     <div className="diff-vector">
                        <img src={diff1} alt="Logo" />
                     </div>
                  </div>
               </div>
            </div>
            </Link>
         </div>
         <div className="right-col">
         <Link to="/bife-manteiga/">
            <div className="card-recipe" >
               <div className="wrapper">
                  <img src={cardfoto3} alt="Logo" />
                  <div className="devider">
                  </div>
                  <div className="titulo absara">
                     Bife com Manteiga e Alecrim com Batata
                  </div>
                  <div className="descr sneak-regular">
                  A carne tenra e macia, envolvida num molho cremoso, propicia-lhe uma experiência plena de sabor.
                  </div>
                  <div className="caracts">
                     <div className="time-vector">
                        <img src={time25} alt="Logo" />
                     </div>
                     <div className="diff-vector">
                        <img src={diff2} alt="Logo" />
                     </div>
                  </div>
               </div>
            </div>
            </Link>
         </div>
      </div>
   </div>
   <div id="section-3" className="red-background">
      <div className="wrapper-container">
      </div>
   </div>
   <div id="section-4" className="">
      <div className="wrapper-container">
        <div className="left-col">
          <div className="wrapper">

          {/* <div className="titulo absara">
              Resultado de animais<br></br> da raça Angus
            </div> */}
          </div>
          

        </div>
        <div className="right-col">
        <div className="wrapper">
          <div className="titulo absara">
          <img src={logoblack} alt="Logo" />
         


          </div>
          <div className="desc sneak-regular">
          
          Oriundos dos EUA, criados nas vastas planícies ricas em cereais, produzem naturalmente carne com um fino e abundante marmoreado de gordura.<br>
          </br>
          Selecionada para seu prazer, nesta carne descobrirá vários níveis de aromas e sabores complexos e exuberantes, numa textura surpreendentemente suculenta e tenra.

          </div>
          </div>
        </div>

      </div>
   </div>
   <div id="section-5">
      <div className="wrapper" data-sal="slide-up" data-sal-delay="300" data-sal-duration="800" data-sal-easing="ease">
         <div className="titulo aller-display">
            Porquê a raça Black Angus?
         </div>
         <div className="imagem-msc">
             <img  src={certis} alt="" />
             <img  src={frescura} alt="" />
             </div>

         {/* <div className="subtitulo sneak-regular opacity ">
         Quando consome peixe com selo MSC tem a certeza que está a consumir peixe selvagem, rastreável e sustentável. <b>O que é que isso significa</b>?
         </div> */}
   
      </div>
      <div className="wrapper2">
         <div className="col1">
            <div className="card" data-sal="slide-up" data-sal-delay="0" data-sal-duration="300" data-sal-easing="ease">
            <img src={check} alt="checkmsc" />
            <div className="texto sneak-regular opacity">
            A raça Angus começa a formar gordura desde um ano de idade, o que eleva a carne a um patamar de excelência, comparativamente com outras raças.
            </div>
            </div>
         </div>
         <div className="col2">
         <div className="card" data-sal="slide-up" data-sal-delay="0" data-sal-duration="300" data-sal-easing="ease">
            <img src={check} alt="checkmsc" />
            <div className="texto sneak-regular opacity">
            A predisposição genética da raça Angus para a elevada deposição de gordura, conduz a um marmoreado sublime e a uma carne tenra e suculenta.</div>
            </div>
        
            </div>
            <div className="col3">
            <div className="card" data-sal="slide-up" data-sal-delay="0" data-sal-duration="300" data-sal-easing="ease">
            <img src={check} alt="checkmsc" />
            <div className="texto sneak-regular opacity">
            Acresce à aptidão natural da raça Angus para uma carne superior, uma alimentação rica em cereais, nas melhores pastagens.


            </div>
            </div>
            </div>
      </div>
      <div className="wrapper-3">
     

         {/* <div className="text sneak-regular opacity">
         Consumir peixe com selo MSC é contribuir para uma transformação global em que as gerações futuras terão os seus recursos assegurados!
         </div> */}
      </div>
   </div>
 

</Layout>
)
export default IndexPage